<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:56:53
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-19 14:38:43
-->
<template>
  <div class="teacher-list">
    <table-list
      title="讲师列表"
      :loading="loading"
      :search-form="searchForm"
      :data="teacherList"
      :columns="columns(this)"
      :operates="operates(this)"
      :operates2="operates2(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <TeacherAccount ref="TeacherAccount" @success="getTeacherList"></TeacherAccount>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  // {
  //   label: '对外开放类型',
  //   method: _this.handleOpen,
  // },
  {
    label: '添加讲师',
    method: _this.handleAdd,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = [
  {
    label: '讲师姓名',
    prop: 'teacherName',
  },
  {
    label: '手机号',
    prop: 'teacherPhone',
  },
  {
    label: '性别',
    type: 'select',
    prop: 'gender',
    children: [
      { value: 1, label: '男' },
      { value: 2, label: '女' },
      // { value: 3, label: '保密' },
    ],
  },
  {
    label: '是否对外开放',
    type: 'select',
    prop: 'isOpen',
    children: [
      { value: true, label: '是' },
      { value: false, label: '否' },
    ],
  },
  {
    label: '课程数量',
    type: 'select',
    prop: 'productCourseCountRange',
    children: [
      { value: 1, label: '1-10' },
      { value: 2, label: '11-30' },
      { value: 3, label: '31-50' },
      { value: 4, label: '50-100' },
      { value: 5, label: '100以上' },
    ],
  },
  {
    label: '上传时间',
    type: 'picker',
    prop: 'dateTime',
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'pic',
    label: '讲师信息',
    width: 150,
    render: (h, { row }) => {
      return [
        h(
          'div',
          {
            // style: 'display:flex;cursor:pointer',
            style: 'cursor:pointer;',
            on: {
              click: () => {
                _this.handleToDetail(row.teacherId)
              },
            },
          },
          [
            row.teacherPic
              ? h('img', {
                  domProps: { src: row.teacherPic },
                  style: 'height:80px;width:80px;margin-right:12px',
                })
              : null,
            h(
              'div',
              {
                style:
                  'display:flex;flex-direction:column;justify-content:space-between;font-size:14px;width:80px;text-align:center',
              },
              [
                h('p', { style: 'color:#FF7B33' }, row.teacherName),
                // h('p', { style: 'color:#333' }, [
                //   row.gender < 3
                //     ? h('span', { style: 'margin-right:26px' }, ['男', '女', ''][row.gender - 1])
                //     : null,
                //   row.age ? h('span', row.age + '岁') : null,
                // ]),
              ],
            ),
          ],
        ),
      ]
    },
  },
  {
    prop: 'gender',
    label: '性别',
    width: 80,
    formatter: row => {
      return row.gender == '1' ? '男' : '女'
    },
  },
  {
    prop: 'teacherPhone',
    label: '手机号',
    width: 110,
  },
  {
    prop: 'birthday',
    label: '出生日期',
    width: 100,
  },
  {
    label: '常驻地区',
    formatter: row => {
      return row.province + '-' + row.city
    },
  },
  // {
  //   width: 120,
  //   prop: 'accessLevel',
  //   label: '是否对外开放',
  //   formatter: row => {
  //     return row.accessLevel == 'PUBLIC' ? '是' : '否'
  //   },
  // },
  {
    width: 120,
    label: '是否对外展示',
    render: (h, { row }) => {
      return [
        h('el-switch', {
          props: { value: row.accessLevel, activeValue: 'PUBLIC', inactiveValue: 'PRIVATE' },
          on: {
            change: async v => {
              await _this.handleOpen(v, row)
            },
          },
        }),
      ]
    },
  },
  {
    prop: 'onlineCourseCount',
    label: '线上课程数量',
    width: 120,
  },
  {
    prop: 'offlineCourseCount',
    label: '线下课程数量',
    width: 120,
  },
  {
    prop: 'createTime',
    label: '创建时间',
    width: 180,
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
  {
    label: '线下课程',
    method: _this.handleCourse,
  },
  {
    label: '授课视频',
    method: _this.handleVideo,
  },
]
const operates2 = _this => [
  {
    label: '创建账号',
    isShow: row => row.userId == 0,
    method: _this.handleCreated,
  },
  {
    label: '修改账号',
    isShow: row => row.userId > 0,
    method: _this.handleCreated,
  },
]
import TableList from '@/components/TableList'
import TeacherAccount from './components/TeacherAccount'
import { getTeacherList, teacherDelete, teacherAccesslevel } from '@/api/teacher'
import to from 'await-to'
export default {
  components: {
    TableList,
    TeacherAccount,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      operates,
      operates2,
      loading: false,
      teacherList: [],
      selectTeachers: [],
      queryParams: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.getTeacherList()
  },
  methods: {
    async getTeacherList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(getTeacherList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.teacherList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      if (queryParams.dateTime) {
        queryParams.startTime = queryParams.dateTime[0]
        queryParams.endTime = queryParams.dateTime[1]
      } else {
        queryParams.startTime = ''
        queryParams.endTime = ''
      }
      this.queryParams = queryParams
      this.getTeacherList()
    },
    //设置对外开放
    async handleOpen(v, row) {
      // if (!this.selectTeachers.length) return this.$message.warning('请先选择讲师')
      // this.$router.push(`/teacher/open?ids=${this.selectTeachers.map(e => e.teacherId).join(',')}`)
      // row.accessLevel = row.accessLevel == 'PUBLIC' ? 'PRIVATE' : 'PUBLIC'
      const [, err] = await to(teacherAccesslevel({ teacherId: row.teacherId, accessLevel: v }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('成功')
      this.getTeacherList()
    },
    //添加讲师
    handleAdd() {
      this.$router.push(`/teacher/add`)
    },
    // 删除
    handleDelete() {
      if (!this.selectTeachers.length) return this.$message.warning('请先选择要删除的讲师')
      let teacherIdList = []
      this.selectTeachers.map(item => teacherIdList.push(item.teacherId))
      this.$confirm('确认删除讲师吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(teacherDelete({ teacherIdList }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getTeacherList()
      })
    },
    //创建账号
    handleCreated(row) {
      this.$refs.TeacherAccount.show(row.teacherId, row.userId, row.teacherName)
    },
    // 编辑
    handleEdit(row) {
      this.$router.push(`/teacher/edit/${row.teacherId}`)
    },
    handleToDetail(id) {
      this.$router.push(`/teacher/${id}`)
    },
    handleCourse(row) {
      this.$router.push({
        path: 'offlineCoursesList',
        query: { teacherId: row.teacherId, teacherName: row.teacherName },
      })
    },
    handleVideo(row) {
      this.$router.push({
        path: '/teacher/lectureVideoList',
        query: { teacherId: row.teacherId, teacherName: row.teacherName },
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectTeachers = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getTeacherList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getTeacherList()
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher-list {
  tr {
    &:last-of-type {
      width: 300px;
    }
  }
  ::v-deep.operate-group {
    // width: 50%;
    float: left;
    span {
      // display: block;
      margin: 0 3px;
    }
  }
}
</style>
