<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="content">
        <div class="teacherName">讲师名称：{{ teacherName }}</div>
        <div v-if="userId > 0" class="beforePhone">原手机号：{{ beforePhone }}</div>
        <div class="phone">
          <div class="text">+86 <i class="el-icon-arrow-down"></i></div>
          <div>
            <el-input v-model="phone" class="input" placeholder="请输入手机号码"></el-input>
          </div>
        </div>
        <div v-if="userId > 0" class="explain">
          注：更改手机号后在明师道的数据将会转到新的手机号上
        </div>
        <div v-else class="explain">
          注：系统将会使用手机号创建账号，如手机号注册账号，将会绑定该账号。请核对正确的手机号谨慎填写
        </div>
      </div>
      <div class="footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">确定创建</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { bingTeacher, searchTeacherPhone } from '@/api/teacher'
import to from 'await-to'
export default {
  data() {
    return {
      dialogVisible: false,
      phone: '',
      title: '',
      teacherId: '',
      userId: '',
      teacherName: '',
      beforePhone: '',
    }
  },
  created() {},
  methods: {
    show(teacherId, userId, teacherName) {
      this.dialogVisible = true
      this.teacherId = teacherId
      this.userId = userId
      this.teacherName = teacherName
      if (this.userId > 0) {
        this.title = '修改账号'
        this.searchTeacherPhoneData()
      } else {
        this.title = '创建账号'
      }
    },
    async searchTeacherPhoneData() {
      const [res, err] = await to(
        searchTeacherPhone({ phoneNumber: this.phone, teacherId: this.teacherId }),
      )
      if (err) return this.$message.warning(err.msg)
      this.beforePhone = res.data
    },
    async submit() {
      if (this.phone == '') return this.$message.warning('请输入手机号')
      let reg = /^1[3-9][0-9]\d{8}$/
      if (!reg.test(this.phone)) return this.$message.warning('请输入正确手机号')
      const [, err] = await to(bingTeacher({ phoneNumber: this.phone, teacherId: this.teacherId }))
      if (err) return this.$message.warning(err.msg)
      if (this.userId > 0) {
        this.$message.success('修改成功')
      } else {
        this.$message.success('创建成功')
      }
      this.dialogVisible = false
      this.$emit('success')
    },
  },
}
</script>

<style scoped lang="scss">
.content {
  width: 300px;
  margin-left: 120px;
  color: #909399;
  .teacherName {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .beforePhone {
    margin: 20px 0;
    font-size: 16px;
  }
  .phone {
    display: flex;
    line-height: 40px;
    padding: 5px 0;
    border-bottom: 1px solid #707070;
    .text {
      padding: 0 10px;
      border-right: 1px solid #909399;
      font-size: 16px;
    }
    .input {
      ::v-deep .el-input__inner {
        border: none;
      }
    }
  }
  .explain {
    margin: 20px 0;
    color: #333;
  }
}
.footer {
  text-align: right;
}
</style>
